import React from 'react'
import Xgplayer from 'xgplayer-react';
interface Props {
    urls:string
}
class Videos extends React.Component<Props> {
    render() {
        let config = {
            // id: 'mse',
            playbackRate: [0.5, 0.75, 1, 1.5, 2],
            videoInit: true,
            url:this.props.urls
        };
        return <Xgplayer config={config} />
    }
};
export default Videos;