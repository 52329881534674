import {moduleType,systemId} from "@/types/index"
export  const navName =(key:moduleType)=>{
    switch (key) {
        case 0:
            return '其他'
        case 1:
            return '常见问题解决'
        case 2:
            return '产品操作流程'
        case 3:
            return '业务通知'
        case 4:
            return '系统升级通知'
        case 5:
            return '产品资料'
        case 6:
            return '人事任免'
        case 7:
            return '销售资料'
        case 8:
            return '公共课程培训'
        case 9:
            return '知识产权'
        case 10:
            return '央视广告资料'
        case 11:
            return '3A资料'        
        default:
            return '全部'
    }
}
export  const pageName =(key:systemId)=>{
    switch (key) {
        case 0:
            return '信融erp'
        case 1:
            return '渠道erp'
        case 2:
            return '制作系统'
        case 3:
            return 'sfa'
        case 4:
            return '流量运行系统'
        case 5:
            return '生意帮'
        case 6:
            return '分销商'
        case 7:
            return '新sfa'
        default:
            return '全部'
    }
}