import request from './request'
import {systemId,moduleType} from "@/types/index"
export function listfun (data:{
    pageSize?:number,
    pageNum?:number,
    systemId:systemId,
    type:moduleType,
    userId:number,
    keyword?:string|null,
    isNotice?:0|1|null
}) { 
    return request({
        headers:{'Content-Type':'multipart/form-data'},
        url: '/help/list',
        method: 'post',
        data
    })
}
export function documentDetails (data:{
    kid:number,
    userId:number
}) { 
    return request({
        headers:{'Content-Type':'multipart/form-data'},
        url: '/help/documentDetails',
        method: 'post',
        data
    })
}
///help/getPreAndNext
export function getPreAndNext (data:{
    systemId:systemId,
    type:moduleType,
    userId:number,
    kid:number
}) { 
    return request({
        headers:{'Content-Type':'multipart/form-data'},
        url: 'help/getPreAndNext',
        method: 'post',
        data
    })
}
export function loginCode (data:{
    username:number
}) { 
    return request({
        url: '/login/loginCode',
        method: 'get',
        data
    })
}
export function checkLogin (data:{
    mobile:number,
    code:number|string
}) { 
    return request({
        url: '/login/checkLogin',
        method: 'get',
        data
    })
}
export function quit (data:{
    employeeId:number
}) { 
    return request({
        url: '/login/quit',
        method: 'get',
        data
    })
}
export function attachment (data:{
    kid:number
}) { 
    return request({
        url: '/help/attachment',
        method: 'get',
        data
    })
}
//