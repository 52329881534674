import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './styles/glabal.scss'
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { Provider } from 'react-redux'
import store from './store/index'
import  "@/router/loadRoutes" //加载url参数
import BasicRoute from '@/router/index';
//<React.StrictMode>  </React.StrictMode>,
ReactDOM.render(
  
    <Provider store={store}>
     <ConfigProvider locale={zh_CN}>  <BasicRoute /></ConfigProvider>
    </Provider>
    ,
    

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
