import React , { createRef }from 'react';
import { Row,Col,Input, Dropdown,Popover} from 'antd';
import head from "@/assets/images/head.png"
import { SearchOutlined,CaretDownOutlined } from '@ant-design/icons';
import {HeadContendProps} from "@/types/interface"
import {systemId} from "@/types/index"
import {pageName} from "@/utils/common"
import {quit} from "@/api/index"
class HeadContend extends React.Component<HeadContendProps> {
	myRef= createRef<Input>();
	pageClick(systemId:systemId){
		this.props.onPage(systemId)
	}
	render() {
		
		const menu = (
			<div className="head_menu">
				<div className="li" onClick={()=>(this.pageClick(null))}>全部</div>
				<div className="li" onClick={()=>(this.pageClick(0))}>信融erp</div>
				<div className="li" onClick={()=>(this.pageClick(1))}>渠道erp</div>
				<div className="li" onClick={()=>(this.pageClick(2))}>制作系统</div>
				<div className="li" onClick={()=>(this.pageClick(3))}>SFA</div>
				{/* <div className="li" onClick={()=>(this.pageClick(4))}>流量运营系统</div> */}
				{/* <div className="li" onClick={()=>(this.pageClick(5))}>生意帮</div> */}
				{/* <div className="li" onClick={()=>(this.pageClick(6))}>分销商</div> */}
				<div className="li" onClick={()=>(this.pageClick(7))}>新SFA</div>
			</div>
		);
		const userId = ()=>{
			let model = localStorage.getItem('module')
			if(!model){
				window.location.href = "/login"
			}
			return JSON.parse(model?model:'null')
		}
		return (
			<Row gutter={10}>
				<Col span="4">
					<img src={head} alt=""/>
				</Col>
				<Col span="10" style={{paddingTop:'3px'}}>
					<Input ref={this.myRef} prefix={<SearchOutlined />} allowClear placeholder="按关键词搜索"  style={{width:"250px",height:"33px",borderRadius: "2000px"}}/>
					<button className="head_abt" onClick={()=>{
						const value = this.myRef.current?.state.value;
						const reg = /^\s*$/g;
						if ((reg.test(value))) {
							this.props.onClick(null)
							return
						}
						this.props.onClick(value)
					}}>搜索</button>
				</Col>
				
				<Col span="10" className="text-right">
				<span style={{paddingRight:'10px',color:'red'}}>右侧可切换各个系统→</span>
				<Dropdown overlay={menu} placement="topCenter" arrow trigger={['click']}>
					<span className="ant-dropdown-link">
						{pageName(this.props.systemId)}
						<CaretDownOutlined />
					</span>
				</Dropdown>
				<Popover
					content={<span  className='ant-dropdown-link' onClick={()=>{
						quit({employeeId:Number(userId().userId)}).then(()=>{
							window.location.href = "/login"
						})
					}}>退出</span>}
					trigger="click"
				>
					{<span className='quit'>{userId().name}</span>}
				</Popover>
				</Col>
				
			</Row> 
		);
	}
}
export default HeadContend
