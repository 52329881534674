// import React from 'react';
import { Route,Redirect ,Switch} from 'react-router-dom';


export const renderRoutes = (routes:any,userIf:boolean = false) =>{
    return routes.length ? (
        <Switch>
            {
                routes.map((route: any, i:number) => {
                    return (
                        <Route
                            exact={route.exact}
                            key={route.key || i}
                            path={route.path}
                            render={
                                props =>{
                                    if(userIf){
                                       
                                        if(window.location.pathname!=='/login'){
                                            window.location.href="/login"
                                        }
                                        return <route.component {...props}  route={route}/>
                                    }else{
                                        console.log(route)
                                        if(!route.render){//没有默认下级
											return <route.component {...props}  route={route}/>
										}else{
											return <>
														<Route exact path={route.path}>
															<Redirect to ={route.render}></Redirect>
														</Route>
														<route.component {...props}  route={route}/>
													</>
										}
                                    }
                                }
                            }
                            strict={route.strict}
                        />
                    )
                })
            }
            <Route path="*" render={
                 () =>{
                     return  <Redirect to ={routes[0].render?routes[0].render:routes[0].path}></Redirect>
                 }
            }>
                
            </Route>
        </Switch>
    ):null
}