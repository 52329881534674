import React from 'react'
import { Pagination} from 'antd';
import { connect } from 'react-redux'
import List from "@/components/List"
import {listfun} from "@/api/index"
import {moduleType,systemId} from "@/types/index"
import {navName} from "@/utils/common"
type propType = {
    modules:{
        key:moduleType,
        keyword:string|null,
        systemId:systemId,
        userId:number
    }
}
interface Props {
    state:propType,
    history:any
}
class ModuleList extends React.Component<Props> {
    formData = {
        pageSize:10,
        pageNum:1,
        total:0,
    }
    type:moduleType = 1
    keyword:string|null = null
    systemId:systemId = 3
    state = {
        list:[]
    }
    UNSAFE_componentWillMount(){
        //组件挂载之前
       
        this.listfun()
    }
    componentWillUnmount = ()=>{
        this.setState = (state,callback)=>{
            return;
        }
     }
    listfun(){
        this.type = this.props.state.modules.key;
        this.keyword = this.props.state.modules.keyword;
        this.systemId = this.props.state.modules.systemId;
        listfun({
            ...this.formData,
            systemId:this.systemId,
            type:this.props.state.modules.key,
            isNotice:null,
            userId:this.props.state.modules.userId,
            keyword:this.keyword
        }).then(res=>{
            this.formData.total =res.data.total
            this.setState({
                list:res.data.list
            })
        }).catch(err=>{
            this.setState({
                list:null
            })
        })
    }
    render() {
        const pagination={
            position:['bottomCenter'],
            showSizeChanger: true,
            showQuickJumper: false,
            pageSize: this.formData.pageSize,
            current: this.formData.pageNum,
            total: this.formData.total,
            pageSizeOptions:['5','10','15','20','50','100'],
            onChange:(pageNum:number,pageSize: number | undefined)=>{
                this.formData = {
                    pageNum,
                    pageSize:pageSize?pageSize:this.formData.pageSize,
                    total:0
                }
                this.listfun()
            }
        }
        //模块变化
        if(this.props.state.modules.key!==this.type){
            this.formData = {
                pageSize:10,
                pageNum:1,
                total:0,
            }
            this.listfun()
        }
        // 搜索
        if(this.props.state.modules.keyword!==this.keyword){
            console.log(this.props.state.modules.keyword,this.keyword)
            this.formData = {
                pageSize:10,
                pageNum:1,
                total:0,
            }
            this.listfun()
        }
        //系统变化
        if(this.props.state.modules.systemId!==this.systemId){
            console.log(this.props.state.modules.keyword,this.keyword)
            this.formData = {
                pageSize:10,
                pageNum:1,
                total:0,
            }
            this.listfun()
        }
        return <div>
            <div className="title_top">
               <div>
               <span>
                    {navName(this.type)}
                </span>
                <div className="color"></div>
               </div>
            </div>
            <div className="list_body">
                <List type={this.props.state.modules.systemId}  list={this.state.list} onClick={(li:any)=>{
                    this.props.history.push({
						pathname:"/index/details",
                        state:li
					});
                }}/>
            </div>
            <div className="pagination">
                {this.state.list?<Pagination  {...pagination}/>:null}
            </div>
        </div>
    }
};
 
export default connect( (state:propType) => ({state}))(ModuleList);