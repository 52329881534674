
import store from '@/store/index'
import {moduleKey} from '@/store/actions/index'



function getQueryVariable(variable:string)
{
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] === variable){return pair[1];}
       }
       return(false);
}
if(getQueryVariable('userId')){
    let name = decodeURI(String(getQueryVariable('name')))
    if(name==='false'){
        name =''
    }
   
    if(localStorage.getItem('module')){
        let module = localStorage.getItem('module')
        let moduleObj  = JSON.parse(module?module:'')
        console.log(moduleObj)
        localStorage.setItem("module",JSON.stringify({
            ...moduleObj,
            key:Number(getQueryVariable('type')),
            systemId:Number(getQueryVariable('systemId'))
        }))
        store.dispatch(moduleKey({
            ...moduleObj,
            key:Number(getQueryVariable('type')),
            systemId:Number(getQueryVariable('systemId'))
        }))
        
    }else{
        localStorage.setItem("module",JSON.stringify({
            systemId:Number(getQueryVariable('systemId')),
            userId:Number(getQueryVariable('userId')),
            key:Number(getQueryVariable('type')),
            name:name
        }))
        store.dispatch(moduleKey({
            systemId:Number(getQueryVariable('systemId')),
            userId:Number(getQueryVariable('userId')),
            key:Number(getQueryVariable('type')),
            name:name
            
        }))
    }
    
}else{
    let module  = localStorage.getItem('module')
    module =JSON.parse(module?module:'null')
    if(module){
        console.log(module)
        store.dispatch(moduleKey(module))
    }
}
