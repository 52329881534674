import React from 'react'
import {Skeleton,Button,message} from 'antd';
import { connect } from 'react-redux'
import {documentDetails,getPreAndNext,attachment} from "@/api/index"
import IconFont from '@/utils/IconFont';
// import {DetailsProps} from "@/types/interface"
import {navName} from "@/utils/common"
import {moduleKey} from '@/store/actions/index'
import {moduleType,systemId} from "@/types/index"
import Videos from "@/components/Videos"
type propType = {
    modules:{
        key:moduleType,
        keyword:string|null,
        systemId:systemId,
        userId:number
    }
}
interface Props {
    state:propType,
    location:any,
    moduleKey:Function,
    history:any
}
class Details extends React.Component<Props> {
    state:{
        data:null|{
            createAuthor: string,
            createAuthorId: number,
            createTime: string,
            id: number,
            ifPush: number,
            ifReading: number,
            ifRoll:number,
            ifUse: number,
            permission:number,
            remarks: string,
            title: string,
            type: moduleType,
            content:string
        }
        preAndnext:{
            next:{
                kid: number,
                title: string
            }|null,
            pre:{
                kid: number,
                title: string
            }|null
        },
        attachment:Array<{
            id: number,
            kid: number,
            name: string,
            type: number,
            url: string
        }>|null
    } = {
        data:null,
        preAndnext:{next:null,pre:null},
        attachment:null
        
    }
    UNSAFE_componentWillMount(){
        //组件挂载之前
        if(this.props.location.state){
            this.documentDetails(this.props.location.state.kid)
            this.getPreAndNext(this.props.location.state.kid)
            this.attachment(this.props.location.state.kid)
        }else{
            if(this.getQueryVariable('kid')){
                this.documentDetails(Number(this.getQueryVariable('kid')))
                this.getPreAndNext(Number(this.getQueryVariable('kid')))
                this.attachment(Number(this.getQueryVariable('kid')))
            }else{
                // this.props.history.push({
                //     pathname:"/index/home",
                // });
            }
        }
        
    }
    componentWillUnmount = ()=>{
        this.setState = (state,callback)=>{
            return;
        }
     }
     getQueryVariable(variable:string){
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] === variable){return pair[1];}
       }
       return(false);
    }
     documentDetails(kid:number){
        documentDetails({
            kid:kid,
            userId:this.props.state.modules.userId,
        }).then(res=>{
            this.setState({
                data:res.data
            })
        }).catch(err=>{

        })
    }
    getPreAndNext(kid:number){
        getPreAndNext({
            systemId:this.props.state.modules.systemId,
            type:this.props.state.modules.key,
            kid:kid,
            userId:this.props.state.modules.userId,
        }).then(res=>{
            this.setState({
                preAndnext:{
                    next:res.data.next.title?res.data.next:null,
                    pre:res.data.pre.title?res.data.pre:null,
                }
            })
        }).catch(err=>{

        })
    }
    attachment(kid:number){
        attachment({
            kid:kid,
        }).then(res=>{
            this.setState({
                attachment:res.data
            })
        })
    }
    render() {
        const {data,preAndnext,attachment} = this.state;
        const htmlEscape =(html:any)=>{
            var html1=html;
          var reg = /(&lt;)|(&gt;)|(&amp;)|(&quot;)/g;
          return html1.replace(reg,function(match:string){
              switch(match){
                  case "&lt;":
                  return "<";
                  case "&gt;":
                  return ">"
                  case "&amp;":
                  return "&";
                  case "&quot;":
                  return "\""
              }
          });
      }
      const copy=(text:string)=> {
        let input = document.createElement('input');
        input.setAttribute('readonly', 'readonly'); // 防止手机上弹出软键盘
        input.setAttribute('value', text);
        document.body.appendChild(input);
        // input.setSelectionRange(0, 9999);
        input.select();
        let res = document.execCommand('copy');
        document.body.removeChild(input);
        message.success('复制成功')
        return res
    }
        return <div className="details">
            <div className="title_top">
                <div>
                    <span>{navName(data?data.type:1)}</span>
                    <div className="color"></div>
                </div>
                <span className="clear" onClick={()=>{
                    // this.props.moduleKey({
                    //     key:li,
                    // })
                    if(this.getQueryVariable('kid')){
                        this.props.history.push({
                            pathname:"/index/moduleList"
                        });
                    }else{
                        window.history.go(-1);
                    }
                    // if(document.referrer === ''){
                    //     this.props.history.push({
                    //         pathname:"/index/moduleList"
                    //     });
                    // }else{
                    //     window.history.go(-1);
                    // }
                    
                    //
                }}>
                   <IconFont type="icon-fanhui" className="iocn"></IconFont> 返回上一级
                </span>
            </div>
            {
               !data?(<Skeleton active />):(
                   <>
                        <div className="details_title">
                           <div>
                                <div className="name">
                                    <span>{data?data.title:null}</span>
                                    <Button  type="link" onClick={()=>{
                                        let url = `http://wen.wayboo.net.cn/index/details?
                                        type=${this.props.state.modules.key}&
                                        systemId=${this.props.state.modules.systemId}&
                                        userId=${this.props.state.modules.userId}&
                                        kid=${this.props.location.state?this.props.location.state.kid:Number(this.getQueryVariable('kid'))}&
                                        name=`
                                        copy(url.replace(/\s*/g,""))
                                    }} style={{
                                        borderRadius:'10px',
                                        border:'1px solid #1890ff',
                                        marginLeft:'10px',
                                        display:'none'
                                    }}>复制分享链接</Button>
                                </div>
                                <div className="time">发布时间：{data?data.createTime:null}</div>
                           </div>
                          
                        </div>
                            {
                                attachment?.map(list=>{
                                    if(list.url.indexOf('.mp4')!==-1){
                                        return <Videos  key={list.id} urls={list.url}/>
                                        // return <div key={list.id} style={{width:'70%'}}><video style={{maxWidth:'100%'}}  src={list.url} controls></video></div>
                                    }else if(list.url.indexOf('.m4a')!==-1){
                                        return <div key={list.id} style={{paddingTop:"20px"}}><audio   src={list.url} controls></audio></div>
                                    }else{
                                        return null
                                    }
                                    
                                })
                            }
                        <div className="details_body" dangerouslySetInnerHTML={{__html: data?htmlEscape(data.content):''}}></div>

                        <div className="attachment">
                            {
                                attachment?.map(list=>{
                                    return <Button key={list.id}  onClick={()=>{
                                        window.open(list.url)
                                    }}>下载_{list.name}</Button>
                                    
                                })
                            }
                        </div>
                        <div className="details_foot">
                            {preAndnext.pre?<div className="li" onClick={()=>{
                                if(preAndnext.pre){
                                    this.documentDetails(preAndnext.pre.kid)
                                    this.getPreAndNext(preAndnext.pre.kid)
                                    this.attachment(preAndnext.pre.kid)
                                }
                            }}>上一篇：{preAndnext.pre?.title}</div>:null}
                            {preAndnext.next?<div className="li" onClick={()=>{
                                if(preAndnext.next){
                                    this.documentDetails(preAndnext.next.kid)
                                    this.attachment(preAndnext.next.kid)
                                }
                            }}>下一篇：{preAndnext.next?.title}</div>:null}
                        </div>
                   </>
               )
            }
        </div>
    }
};

export default connect( (state:propType) => ({state}),{moduleKey})(Details);