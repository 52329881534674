import React from 'react'
import {Skeleton,Empty} from 'antd';
import {list} from "@/types/interface"
import moment from 'moment';
import IconFont from '@/utils/IconFont';
import {pageName,navName} from "@/utils/common"
class Lists extends React.Component<list> {
    render() {
        return <div className="gloal_list">
            {
                this.props.list?.map((li,index:number)=>{
                    return <div className="gloal_list_li" key={index} onClick={()=>(this.props.onClick?this.props.onClick(li):null)}>
                        <div className="title">
                            <div className="name">
                                【
                                    {
                                        li.type===3|| li.type===5|| li.type===6|| li.type===7?navName(li.type):(this.props.type===null?pageName(li.systemId):pageName(this.props.type))
                                    }
                                】{li.title}
                                {/* <Tag color="magenta">常见问题</Tag> */}
                            </div>  
                            <div className="time"></div>
                        </div>
                        <div className="num"> 
                        <span className="time">{moment(li.createTime).format('YYYY-MM-DD')} </span>
                         <IconFont type="icon-yanjing"></IconFont><span className="readNum">{li.readNum+199}</span>
                         </div>
                    </div>
                })
            }
            {
               this.props.list===null?(<div style={{marginTop:'150px'}}><Empty/></div>):(this.props.list.length===0?(<Skeleton active/>):null)
            }
        </div>
    }
};
export default Lists;