
 import axios from 'axios';
 import qs from 'qs'
 import { message } from 'antd';
 import NProgress from 'nprogress'
 import 'nprogress/nprogress.css'
 import {
    TIMEOUT,
    baseURL
  } from './config'
 const service = axios.create({
    baseURL: baseURL.dev, 
    timeout: TIMEOUT, 
    // headers: {
    //   'Content-Type': 'application/json;charset=UTF-8'
    // }
 });
 service.interceptors.request.use((config: any) => {
    NProgress.start();
    //  return config;
    if (config.method === 'post') {
        const test =  config.data instanceof FormData;
        if (config.headers['Content-Type']==="multipart/form-data"&&(!test)) {
            const formData = new FormData();
            for(let i in config.data){
                if(config.data[i]!==null){
                    formData.append(i,config.data[i])
                }
                
            }
            config.data = formData
            return config
        }else{
            return config
        }
        
    }
    if (config.method === 'get' && config.data && Object.keys(config.data).length) {
        config.params = qs.parse(config.data)
        return config
    }
    if (config.method === 'get') {
        return config
    }
 }, (error: any) => {
     Promise.reject(error);
 });

 /* respone拦截器 */
 service.interceptors.response.use(
     (response: any) => {
        NProgress.done();
        const res = response.data;
        if(res.code === 401){
            window.location.href = "/login"
        }
        if(res.code === 402){
            message.error(response.data.msg);
            window.location.href = "/login"
        }
        if (res.code !== 0) {
            message.error(response.data.msg);
            return Promise.reject('error');
        }else {
            return response.data;
        }
     },
     (error: any) => {
        
        NProgress.done();
         // 异常处理
        message.error((error.response?error.response.status:''));
        return Promise.reject(error);
     },
 );

 export default service;
