import React from 'react';
// import {Result} from 'antd';
import {propType} from "@/utils/propType"
import { BrowserRouter} from 'react-router-dom';
import {renderRoutes} from "@/router/renderRoutes"
import { connect } from 'react-redux'
import { addRouter} from '@/store/actions/index'

import {router} from './routers'

interface Props {
    state:propType,
    addRouter:Function
}
class BasicRoute extends React.Component<Props> {
    render(){
        return(
            <BrowserRouter>
                    {
                        renderRoutes(router)
                    }
                    
            </BrowserRouter>
        )
    }
}
 export default connect(
    (state:propType) => ({state}),
    { addRouter }
  )(BasicRoute)