import  React,{ Component } from 'react';
import { Row, Col, Form, Input, Button,message} from 'antd';
import { FormInstance } from 'antd/es/form';
import {checkLogin,loginCode} from "@/api/index"
type StateType = {
    code:string,
    referrer:string|null
}
type propType = {
    name: string;
    [propName: string]: any;
};
interface login {
    state: StateType;
    props:propType
  }
class login extends Component {
    formRef = React.createRef<FormInstance>();
    constructor(props: any) {
        super(props);
        this.state = {
            code: '获取验证码',
            referrer:null
        }
    }
    UNSAFE_componentWillMount() {
        //组件挂载之前
        localStorage.clear()
        console.log(document.referrer)
        if(window.navigator.userAgent.indexOf('Chrome')==-1){
            message.warning('请使用谷歌浏览器访问本网站');
        }
        
        if(document.referrer.indexOf("&userId")!==-1){
            this.setState({
                referrer:document.referrer
            })
        }
    }
    componentWillUnmount = () => {
      
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        const onFinish = (values: any) => {
            console.log('Success:', values);
            checkLogin({
                mobile:Number(values.username),
                code:values.code
            }).then((res:any)=>{
                if(this.state.referrer){
                    // console.log(getQueryVariable('type',document.referrer))
                    window.location.href="/index/details?type="+getQueryVariable('type',document.referrer)+"&systemId="+getQueryVariable('systemId',document.referrer)+"&userId="+res.data.id+"&kid="+getQueryVariable('kid',document.referrer)+"&name="+res.data.name
                }else{
                    window.location.href="/?userId="+res.data.id+"&name="+res.data.name
                }
                
            })
        };
        const getQueryVariable = (variable:string,url:string)=>{
               let query = url.split("?")[1];
               let vars = query.split("&");
               for (let i=0;i<vars.length;i++) {
                    let pair = vars[i].split("=");
                    if(pair[0] === variable){return pair[1];}
               }
               return(0);
        }
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };
        const loginCodes = ()=>{
            let username = this.formRef.current!.getFieldValue('username');
            if(!username){
                return;
            }
            if(this.state.code!=='获取验证码'){
                return;
            }
            let index= 60;
            let time:any;
            loginCode({
                username:Number(username)
            }).then((res:any)=>{
                time= setInterval(()=>{
                    index--
                    if(index===0){
                        this.setState({code:'获取验证码'})
                        clearInterval(time)
                    }else{
                        this.setState({code:index+'s'})
                    }
                },1000)
                message.success(res.msg);
            }).catch(err=>{
                console.log(err)
            })
        };
        return <div className="login">
            <div className="login_main">
                <Row>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <div className="login_right">
                            <div className="login_title">
                                <div className="h1">
                                    Hi，Welcome
                                </div>
                                <div className="h2">
                                    信融科技集团学习交流平台
                                </div>
                            </div>
                            <Form
                                ref={this.formRef}
                                name="loginFrom"
                                size="large"
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="手机号"
                                    name="username"
                                    rules={[{ required: true, message: '请输入手机号' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="验证码"
                                    name="code"
                                    rules={[{ required: true, message: '请输入验证码' }]}
                                >
                                    <Input suffix={<span onClick={loginCodes}>{this.state.code}</span>} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block>
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    }
};
export default login