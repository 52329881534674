import React from 'react'
import { connect } from 'react-redux'
import {moduleKey} from '@/store/actions/index'
// import { Row,Col} from 'antd';
import home from "@/assets/images/home.png"
import home1 from "@/assets/images/home1.png"
import home2 from "@/assets/images/home2.png"
import home3 from "@/assets/images/home3.png"
import home4 from "@/assets/images/home4.png"
import {moduleType,systemId} from "@/types/index"
import moment from 'moment';
import {listfun} from "@/api/index"
import {pageName,navName} from "@/utils/common"
type propType = {
    modules:{
        key:moduleType,
        keyword:string|null,
        systemId:systemId ,
        userId:number ,
        name?:string
    }
}
interface Props {
    state:propType,
    moduleKey:Function,
    history:any
}
type list = Array<{
    createTime: string,
    kid: number,
    readNum: number,
    systemId:systemId ,
    type:moduleType ,
    title: string
}>|null
class Home extends React.Component<Props> {
    systemId:systemId = 3
    formData = {
        pageSize:5,
        pageNum:1,
        total:0,
        userId:this.props.state.modules.userId,
    }
    state :{
        list1:list,
        list2:list,
        list3:list,
        list4:list,
    }={
        list1:[],
        list2:[],
        list3:[],
        list4:[],
    }
    UNSAFE_componentWillMount(){
        //组件挂载之前
        this.listfun()
    }
    componentWillUnmount = ()=>{
        this.setState = (state,callback)=>{
            return;
        }
     }
    listfun(){
        this.systemId = this.props.state.modules.systemId;
        Promise.all([listfun({
            ...this.formData,
            systemId:null,
            type:null,
            isNotice:1
        }),listfun({
            ...this.formData,
            systemId:this.props.state.modules.systemId,
            type:5,
        }), listfun({
            ...this.formData,
            systemId:this.props.state.modules.systemId,
            type:4,
        }), listfun({
            ...this.formData,
            systemId:this.props.state.modules.systemId,
            type:2,
        })]).then((values) => {
            console.log(values);
            this.setState({
                list1:values[0].data?.list,
                list2:values[1].data?.list,
                list3:values[2].data?.list,
                list4:values[3].data?.list,
            })
        }).catch(err=>{
            
        })
    }
    render() {
         //系统变化
        if(this.props.state.modules.systemId!==this.systemId){
            this.listfun()
        }
        const onHref = (li:any)=>{
            this.props.history.push({
                pathname:"/index/details",
                state:li
            });
        }
        const onlist = (li:number)=>{
            this.props.moduleKey({
                key:li,
            })
            this.props.history.push({
                pathname:"/index/moduleList"
            });
        }
        return <div className="home">
            <div className="title_top">
               <div>
                    <span>{this.props.state.modules.name?(this.props.state.modules.name+','):null}欢迎来到信融学习平台</span>
                    <div className="color"></div>
               </div>
            </div>
            <div className="home_list">
                <div className="list">
                    <div className="list_title">
                        <div>
                            <img src={home1} alt=""/>
                            公司公告
                        </div>
                        <span onClick={()=>{onlist(10)}}>更多内容+</span>
                    </div>
                    <div className="list_ul">
                        {
                            this.state.list1?.map((li,index:number)=>{
                                return  <div className="li" key={index} onClick={()=>(onHref(li))}>
                                    <span className="name">【{navName(li.type)}】{li.title}</span>
                                    <span className="time">{moment(li.createTime).format('YYYY-MM-DD')}</span>
                                </div>
                            })
                        }
                        
                    </div>
                    <img src={home} alt="" className="position"/>
                </div>
                <div className="list">
                    <div className="list_title">
                        <div>
                            <img src={home4} alt=""/>
                            学习资料
                        </div>
                        <span onClick={()=>{onlist(5)}}>更多内容+</span>
                    </div>
                    <div className="list_ul">
                        {
                            this.state.list2?.map((li,index:number)=>{
                                return  <div className="li" key={index} onClick={()=>(onHref(li))}>
                                    <span className="name">【{pageName(li.systemId)}】{li.title}</span>
                                    <span className="time">{moment(li.createTime).format('YYYY-MM-DD')}</span>
                                </div>
                            })
                        }
                        
                    </div>
                    
                </div>
                <div className="list">
                    <div className="list_title">
                        <div>
                            <img src={home2} alt=""/>
                            系统升级通知
                        </div>
                        <span onClick={()=>{onlist(4)}}>更多内容+</span>
                    </div>
                    <div className="list_ul">
                        {
                            this.state.list3?.map((li,index:number)=>{
                                return  <div className="li" key={index} onClick={()=>(onHref(li))}>
                                    <span className="name">【{pageName(li.systemId)}】{li.title}</span>
                                    <span className="time">{moment(li.createTime).format('YYYY-MM-DD')}</span>
                                </div>
                            })
                        }
                        
                    </div>
                    <img src={home} alt="" className="position"/>
                </div>
                <div className="list">
                    <div className="list_title">
                        <div>
                            <img src={home3} alt=""/>
                            产品操作流程
                        </div>
                        <span onClick={()=>{onlist(2)}}>更多内容+</span>
                    </div>
                    <div className="list_ul">
                        {
                            this.state.list4?.map((li,index:number)=>{
                                return  <div className="li" key={index} onClick={()=>(onHref(li))}>
                                    <span className="name">【{pageName(li.systemId)}】{li.title}</span>
                                    <span className="time">{moment(li.createTime).format('YYYY-MM-DD')}</span>
                                </div>
                            })
                        }
                    </div>
                    
                    
                </div>
                
            </div>
        </div>
    }
};

export default connect( (state:propType) => ({state}),{moduleKey})(Home);