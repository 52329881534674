import { MODULE_TYPE} from "../actions/actionTypes";
const moduleState = {
    key:-4,
    keyword:null,
    systemId:-1,
    userId:-1
};

export const modules= (state = moduleState, action:any)=> {
  switch (action.type) {
    
    case MODULE_TYPE: {
      localStorage.setItem('module',JSON.stringify({
        ...state,
       ...action.payload,
       keyword: null
      }))
      return {
        ...state,
       ...action.payload
      };
    }
    default:
      return state;
  }
}
