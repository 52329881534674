import Layout from '@/layout/index'
import App from '@/App'
import Home from '@/views/home/index'
import login from '@/views/login/index'
import details from '@/views/details/index'
import moduleList from '@/views/moduleList/index'
const router = [
    {
        path: '/',
        url:"App",
        component:App,
        render:"/index",
        children:[
            {
                path: '/index',
                component:Layout ,
                url:"layout/index",
                render:"/index/home",
                children: [
                    {
                        path: '/index/home',
                        component:Home ,
                        title:"首页",
                        icon:"icon-sy",
                    },
                    {
                        path: '/index/moduleList',
                        component:moduleList ,
                        title:"模块列表",
                        icon:"icon-sy",
                    },
                    {
                        path: '/index/details',
                        component:details ,
                        title:"详情页",
                        icon:"icon-sy",
                    }
                ]
            },
            {
                path: '/login',
                component:login ,
                title:"登陆",
                icon:"icon-sy",
            }
        ]
    },
    
]
export {router}