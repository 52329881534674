import { ADD_ROUTER,USER_DATA,CLIENT_ADMIN,MODULE_TYPE} from "./actionTypes";


export const addRouter = (content:any) =>{
  return  ({
    type: ADD_ROUTER,
    payload:content
  })
}

export const userData = (content:any) =>{
  return  ({
    type: USER_DATA,
    payload:content
  })
}

export const clientData = (content:any) =>{
  return  ({
    type: CLIENT_ADMIN,
    payload:content
  })
}
export const moduleKey = (content:any) =>{
  return  ({
    type: MODULE_TYPE,
    payload:content
  })
}