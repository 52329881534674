const isProd = process.env.NODE_ENV === 'production'
export const TIMEOUT = 60000

export const baseURL = isProd?{
	dev:"/api",
	// dev:"/api",
	// dev2:"/api1",
	// dev3:"/api1",
	// action:"/api/customer/yyurl"
}:{
	dev:"/api",
}
