import React from 'react';
import {renderRoutes} from "@/router/renderRoutes"
type propType = {
    route:any
};
interface App {
    props:propType
}
class App extends React.Component {
    public render(){
        return renderRoutes(this.props.route.children)
    }
}
export default App