import React from 'react';
import { Menu} from 'antd';
import { Link } from 'react-router-dom'
import IconFont from '@/utils/IconFont';
import { SoundOutlined } from '@ant-design/icons';
import {NavContendProps} from "@/types/interface"
const { SubMenu } = Menu;
//模块类型：0其他 1常见问题解决 2产品操作流程 3.客服资料 4.系统升级公告 5.客服资料
class NavContend extends React.Component<NavContendProps> {
	key:string[] = [];
	render() {
		return (
			<Menu
				selectedKeys={this.props.defaultSelectedKeys}
				// defaultOpenKeys={['10']}
				mode="inline"
				theme="dark"
				onOpenChange={(e)=>{
					
					let keys = e as string[]
					if(this.key.length===0){
						this.key= keys
					}else{
						this.key= [keys[1]]
					}
					// if(e[0]==='20'){
					// 	return
					// }
					console.log(this.key)
					if(this.key.indexOf('10')!==-1){
						this.props.onOpenChange(10)
					}
				}}
				onClick={(e)=>(this.props.onClick(e))}
			>
				<Menu.Item key={-4} icon={<IconFont type="icon-home"></IconFont>}>
					<Link to="/index/home">平台首页</Link>
				</Menu.Item>
				<SubMenu  key="key10"  title="公司公告" icon={<IconFont type="icon-gonggao"></IconFont>}>
					<Menu.Item key={3}>
						<Link to="/index/moduleList">政策文件</Link>
					</Menu.Item>
					
					<Menu.Item key={6}>
						<Link to="/index/moduleList">人事任免</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key={'key20'}  title="学习资料" icon={<IconFont type="icon-shu"></IconFont>}>
					<Menu.Item key={7}>
						<Link to="/index/moduleList">销售资料</Link>
					</Menu.Item>
					<Menu.Item key={5}>
						<Link to="/index/moduleList">产品资料</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key={'key30'}  title="客服体系知识库" icon={<IconFont type="icon-shu"></IconFont>}>
					<Menu.Item key={8}>
						<Link to="/index/moduleList">公共课程培训</Link>
					</Menu.Item>
					<Menu.Item key={9}>
						<Link to="/index/moduleList">知识产权</Link>
					</Menu.Item>
					<Menu.Item key={10}>
						<Link to="/index/moduleList">央视广告资料</Link>
					</Menu.Item>
					<Menu.Item key={11}>
						<Link to="/index/moduleList">3A资料</Link>
					</Menu.Item>
				</SubMenu>
				<Menu.Item key={4} icon={<IconFont type="icon-Notification"></IconFont>}>
					<Link to="/index/moduleList">系统升级通知</Link>
				</Menu.Item>
				<Menu.Item key={2} icon={<IconFont type="icon-liuchengshezhi"></IconFont>}>
					<Link to="/index/moduleList">产品操作流程</Link>
				</Menu.Item>
				<Menu.Item key={1} icon={<IconFont type="icon-wenti-"></IconFont>}>
					<Link to="/index/moduleList">常见问题解决</Link>
				</Menu.Item>
				<Menu.Item key={0} icon={<SoundOutlined />}>
					<Link to="/index/moduleList">其他</Link>
				</Menu.Item>
			</Menu>
		);
	}
}
export default NavContend;
