import React from 'react';
// import { Link } from 'react-router-dom'
import {renderRoutes} from "@/router/renderRoutes";
import {moduleKey} from '@/store/actions/index'
import {Layout} from 'antd';
import NavContend from "./components/NavContend"
import HeadContend from "./components/HeadContend"
import logo from "@/assets/images/xr_logo.png"
import { connect } from 'react-redux'
import {moduleType,systemId} from "@/types/index"
const { Header, Content,Sider } = Layout;
type propType = {
    modules:{
        key:moduleType,
        keyword:string|null,
        systemId:systemId 
    }
}
interface Props {
	state:propType,
    moduleKey:Function,
	route:any,
	history:any
}
class Layouts extends React.Component<Props> {
    state = {
		defaultSelectedKeys:['-4']
	};
    render(){
        return(
            <Layout className="layout">
				<Sider trigger={null} collapsible className="layout_Sider">
					<div className="logo" onClick={()=>{
						this.props.history.push({
							pathname:"/index/home",
						});
						this.setState({
							defaultSelectedKeys:['null']
						})
					}}>
						<img src={logo} width="100%" alt=""/>
						
					</div>
                    <NavContend onOpenChange={()=>{
						this.props.moduleKey({
							key:10,
						});
						this.props.history.push({
							pathname:"/index/moduleList",
						});
					}}  defaultSelectedKeys={[''+this.props.state.modules.key]} onClick={(e:any)=>{
						this.props.moduleKey({
							key:Number(e.key),
							keyword:null
						})
						this.setState({
							defaultSelectedKeys:[e.key]
						})
					}}/>
				</Sider>
				<Layout>
					<Header style={{backgroundColor:'#fff',padding:"0 35px"}}>
						<HeadContend onClick={(e:any)=>{
							this.props.moduleKey({
								keyword:e
							})
						}} onPage={(e:systemId)=>{
							this.props.moduleKey({
								systemId:e
							})
							// this.props.history.push({
							// 	pathname:"/index/moduleList",
							// });
						}} systemId={this.props.state.modules.systemId}/>
					</Header>
					<Content>
						<div className="main_box">
							{renderRoutes(this.props.route.children)}
						</div>
					</Content>
				</Layout>
			</Layout>
        )
    }
}
export default connect( (state:propType) => ({state}),{moduleKey})(Layouts)